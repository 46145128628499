export default {
  namespaced: true,
  state: {
    category: 'pricing',
    leaderBoard: {},
    pricingList: [],
    promotionText: {},
  },
  getters: {
    leaderBoard(state) {
      return state.leaderBoard;
    },
    promotionText(state) {
      return state.promotionText;
    },
    category(state) {
      return state.category;
    },
    pricingList(state) {
      return state.pricingList;
    },
  },
  actions: {
    getLeaderBoard({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'leaderboard' }, { root: true })
        .then((res) => {
          if (res.length > 0) {
            commit('SET_LEADERBOARD', res[0]);
          }
        })
        .catch();
    },
    getPromotionText({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'promotion-text' }, { root: true })
        .then((res) => {
          if (res.length > 0) {
            commit('SET_PROMOTION_TEXT', res[0]);
          }
        })
        .catch();
    },
    getPricingList({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'pricing-list' }, { root: true })
        .then((res) => {
          commit('SET_PRICING_LIST', res);
        })
        .catch();
    },
  },
  mutations: {
    SET_LEADERBOARD: (state, data) => {
      state.leaderBoard = data;
    },
    SET_PROMOTION_TEXT: (state, data) => {
      state.promotionText = data;
    },
    SET_PRICING_LIST: (state, data) => {
      state.pricingList = data;
    },
  },
};
