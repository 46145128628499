<template>
  <section class="make-schedule">
    <div class="container">
      <div class="card">
        <div class="img-container">
          <img src="@/assets/images/schedule.svg" alt="Zext Schedule">
        </div>
        <div class="content-container">
          <div class="title">Make Schedule</div>
          <div class="sub-title">Pick a time and let's discuss the solution for your problem.</div>
          <div class="text">
            Zext, is a product and services company which helps enterprises achieve
            their data ambitions.
          </div>
          <div class="button-container">
            <a class="btn btn-danger" @click="onPickPlanClick">
              <span>Pick a Plan</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MakeSchedule',
  methods: {
    onPickPlanClick() {
      // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({ url: 'https://calendly.com/zext' });
      return false;
    },
  },
};
</script>

<style scoped>

</style>
