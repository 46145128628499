import Vue from 'vue';
import textHelper from '@/helper/textHelper';
import VueSession from 'vue-session';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store from './store';

import 'slick-carousel/slick/slick.css';
import 'select2/dist/css/select2.min.css';
import '@/assets/css/style.css';

window.$ = require('jquery');
window.jQuery = require('jquery');
window.select2 = require('select2');

Vue.use(VueSession, { persist: true });
Vue.use(VueGtag, {
  config: { id: 'G-97ZHCL1VE9' },
});
Vue.mixin(textHelper);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
