import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import CaseStudies from '@/views/CaseStudies.vue';
import Resources from '@/views/Resources.vue';
import Solutions from '@/views/Solutions.vue';
import DataMarket from '@/views/DataMarket.vue';
import Company from '@/views/Company.vue';
import Pricing from '@/views/Pricing.vue';
import ContentDetail from '@/views/ContentDetail.vue';
import PageDetail from '@/views/PageDetail.vue';
import PageNotFound from '@/views/PageNotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    // path: '/solution/intro',
    path: '/solution',
    name: 'Solutions',
    component: Solutions,
  },
  {
    // path: '/case-studies/list',
    path: '/case-studies',
    name: 'CaseStudies',
    component: CaseStudies,
  },
  {
    // path: '/resources/list',
    path: '/resources',
    name: 'Resources',
    component: Resources,
  },
  {
    path: '/data-market',
    name: 'DataMarket',
    component: DataMarket,
  },
  {
    path: '/company/about',
    name: 'Company',
    component: Company,
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
  },
  {
    path: '/content/:name',
    name: 'ContentDetail',
    component: ContentDetail,
  },
  {
    path: '/page/:name',
    name: 'PageDetail',
    component: PageDetail,
  },
  {
    path: '*',
    name: 'NotFound',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
