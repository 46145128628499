<template>
  <footer>
    <div class="container">
      <!-- Logo -->
      <div class="logo-container">
        <img src="@/assets/images/logo.svg" width="166" height="47" alt="Zext">
      </div>
      <!-- #END# Logo -->

      <!-- Links -->
<!--      <div class="links-container">-->
<!--        <div class="links-sub">-->
<!--          <div class="title">Products</div>-->
<!--          <ul class="list">-->
<!--            <li class="list-item">-->
<!--              <a href="/page/zext-gateway" class="link">Zext Gateway</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/zext-enterprise" class="link">Zext Enterprise</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/get-started" class="link">Get Started</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/subscriptions" class="link">Subscriptions</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="links-sub">-->
<!--          <div class="title">Resources</div>-->
<!--          <ul class="list">-->
<!--            <li class="list-item">-->
<!--              <a href="/page/e-books" class="link">eBooks</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/webinars" class="link">Webinars</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/briefs" class="link">Briefs</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="#" class="link">Blog</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/api-gateway" class="link">API Gateway</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/microservices" class="link">Microservices</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="links-sub">-->
<!--          <div class="title">Company</div>-->
<!--          <ul class="list">-->
<!--            <li class="list-item">-->
<!--              <a href="/company/about" class="link">About</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/customers" class="link">Customers</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/events" class="link">Events</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/investors" class="link">Investors</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/careers" class="link">Careers</a>-->
<!--              <span class="balloon">Hiring!</span>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/partners" class="link">Partners</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/press" class="link">Press</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="/page/contact" class="link">Contact</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--&lt;!&ndash;        <div class="links-sub">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="title">Open Source</div>&ndash;&gt;-->
<!--&lt;!&ndash;          <ul class="list">&ndash;&gt;-->
<!--&lt;!&ndash;            <li class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;              <a href="#" class="link">Install Zext Gateway</a>&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;              <a href="#" class="link">Zext Community</a>&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;              <a href="#" class="link">Kubernetes Ingress</a>&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;              <a href="#" class="link">Same to you</a>&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;              <a href="#" class="link">Insomnia</a>&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->
<!--&lt;!&ndash;          </ul>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="links-sub">-->
<!--          <div class="title">Follow Us</div>-->
<!--          <ul class="list">-->
<!--            <li class="list-item">-->
<!--              <a href="https://www.linkedin.com" target="_blank" class="link">Linkedin</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="https://www.crunchbase.com" target="_blank" class="link">Crunchbase</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="https://twitter.com" target="_blank" class="link">Twitter</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="https://medium.com" target="_blank" class="link">Medium</a>-->
<!--            </li>-->
<!--            <li class="list-item">-->
<!--              <a href="http://github.com" target="_blank" class="link">Github</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
      <!-- #END# Links -->

      <!-- Address & Tax -->
      <div class="address-tax-container">
        <div class="left">
          <div class="address">Zext S.r.l | Via Teodoro Valfré 11 - 00165 ROME, Italy</div>
        </div>
        <div class="right">
          <div class="tax">VAT/C.F./P.IVA: 16887691000 | Roma Chamber of Commerce CS: 10400 Eur (I.V.)</div>
        </div>
      </div>
      <!-- #END# Address & Tax -->

      <!-- Subscribe & Brand Download -->
<!--      <div class="subscribe-brand-container">-->
<!--        <div class="left">-->
<!--          <form class="form-subscribe">-->
<!--            <label for="subscribeEmail" class="text keep-spaces"><strong>Enter the Fold</strong>&nbsp;Subscribe to our newsletter</label>-->
<!--            <div class="form-group">-->
<!--              <input class="input" id="subscribeEmail" name="subscribeEmail" type="email"-->
<!--                     placeholder="What's your email?"-->
<!--                     required/>-->
<!--              <button class="btn btn-success" type="submit">-->
<!--                <span>Subscribe</span>-->
<!--              </button>-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <div class="brand-container">-->
<!--            <div class="logo-text-container">-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">-->
<!--                <g fill="none" fill-rule="evenodd">-->
<!--                  <g fill="#DBDBDB">-->
<!--                    <g>-->
<!--                      <g>-->
<!--                        <path d="M15.843 13.68c1.191 0 2.157.967 2.157 2.16S17.034 18 15.843 18c-.372-->
<!--                        0-.72-.094-1.026-.26-.515-.264-1.508-.692-2.39-.692-.916-->
<!--                        0-1.951.463-2.448.722l.042-.02-.016.008-.026.012c-.29.147-.62.23-.968.23-1.191-->
<!--                        0-2.157-.967-2.157-2.16s.966-2.16 2.157-2.16c.349-->
<!--                        0 .678.083.969.23.496.258 1.53.724 2.447.724.888 0-->
<!--                        1.887-.437 2.4-.7l.011-.006c.3-.158.642-.248 1.005-.248zm-13.686 0c1.192 0-->
<!--                        2.158.967 2.158 2.16S3.349 18 2.157 18C.966 18 0 17.033 0 15.84s.966-2.16-->
<!--                        2.157-2.16zm6.854-6.84c1.192 0 2.158.967 2.158 2.16s-.966 2.16-2.158 2.16c-1.191-->
<!--                        0-2.157-.967-2.157-2.16s.966-2.16 2.157-2.16zM15.843 0C17.034 0 18 .967 18 2.16s-.966-->
<!--                        2.16-2.157 2.16c-1.192 0-2.158-.967-2.158-2.16S14.651 0 15.843 0zM8.989 0c1.191 0-->
<!--                        2.157.967 2.157 2.16s-.966 2.16-2.157 2.16c-.371-->
<!--                        0-.72-.094-1.026-.26-.515-.264-1.508-.692-2.39-.692-.916-->
<!--                        0-1.95.463-2.448.722l.042-.02-.016.008-.026.012c-.29.147-.62.23-.968.23C.966-->
<!--                        4.32 0 3.353 0 2.16S.966 0 2.157 0c.349 0 .678.083.97.23.495.258 1.53.724-->
<!--                        2.446.724.888 0 1.887-.437 2.4-.7l.011-.006C8.284.09 8.626 0 8.99 0z"-->
<!--                              transform="translate(-1283 -5281) translate(0 4126) translate(1283 1155)"/>-->
<!--                      </g>-->
<!--                    </g>-->
<!--                  </g>-->
<!--                </g>-->
<!--              </svg>-->
<!--              <div class="text"><strong>Zext</strong> Brand</div>-->
<!--            </div>-->
<!--            <a href="#" class="btn btn-danger" download>-->
<!--              <span>Download</span>-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <!-- #END# Subscribe & Brand Download -->

      <!-- Terms & Copyright -->
      <div class="terms-copyright-container">
        <div class="left">
<!--          <a href="#" class="link">Terms - Privacy</a>-->
        </div>
        <div class="right"></div>
      </div>
      <!-- #END# Terms & Copyright -->
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: (new Date()).getFullYear(),
    };
  },
};
</script>

<style scoped>

</style>
