export default {
  namespaced: true,
  state: {
    category: 'data-market',
    leaderBoard: {},
    dataSetList: [],
  },
  getters: {
    leaderBoard(state) {
      return state.leaderBoard;
    },
    category(state) {
      return state.category;
    },
  },
  actions: {
    getLeaderBoard({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'leaderboard' }, { root: true })
        .then((res) => {
          if (res.length > 0) {
            commit('SET_LEADERBOARD', res[0]);
          }
        })
        .catch();
    },
    getDataSetList({ commit, getters }, data) {
      const category = typeof data === 'undefined' ? getters.category : data;

      return this.dispatch('getDataByPageAndTag', { category, tag: 'dataset-list' }, { root: true })
        .then((res) => {
          commit('SET_DATASET_LIST', res);
        })
        .catch();
    },
  },
  mutations: {
    SET_LEADERBOARD: (state, data) => {
      state.leaderBoard = data;
    },
    SET_DATASET_LIST: (state, data) => {
      state.dataSetList = data;
    },
  },
};
