<template>
  <div id="app">
    <div class="preloader">
      <img src="@/assets/images/preloader.svg" height="150" alt="Zext.com loading..." />
    </div>
    <Header v-if="commonDataFetched"></Header>
    <router-view v-if="commonDataFetched"/>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';

export default {
  name: 'Home',
  components: { Footer, Header },
  data() {
    return {
      commonDataFetched: false,
    };
  },
  async created() {
    this.$session.start();
    this.commonDataFetched = false;
    await Promise.all([
      this.$store.dispatch('getPostCategories'),
      this.$store.dispatch('getPostTags'),
    ]);
    this.commonDataFetched = true;
  },
};
</script>

<style>
</style>
