<template>
  <div>
    <section class="hero-with-video">
      <div class="container">
        <div class="video-container">
          <div class="close-video">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 20 20">
              <g fill="none" fill-rule="evenodd">
                <g stroke="#000" stroke-width=".5">
                  <g>
                    <path d="M7 0L7 14M14 7L0 7"
                          transform="translate(-1209 -71) rotate(45 523.674 1507.013)"/>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <video loop>
            <source data-src="https://videos.files.wordpress.com/DVEI3VMw/zext_hd.mp4" type="video/mp4"/>
            Your browser does not support HTML video.
          </video>
        </div>

        <div class="left">
          <!-- Card of Form -->
          <div class="card">
            <div class="pricing-calc-container">
              <div class="title" v-if="headline.title">{{ headline.title.rendered }}</div>
              <div class="text" v-if="headline.content" v-html="headline.content.rendered">
              </div>
              <div class="button-container js-play-video">
                <button class="btn btn-transparent">
                  <span>Watch video</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g transform="translate(-684 -659) translate(684 659)">
                          <circle cx="34" cy="34" r="34" fill="#E2241C"/>
                          <path fill="#FFF"
                                d="M20.738 22h21.076c.626 0 1.134.508 1.134 1.134V44.21c0 .627-.508 1.134-1.134 1.134-.3 0-.59-.12-.802-.332L30.474 34.474 19.936 23.936c-.443-.443-.443-1.161 0-1.604.213-.213.501-.332.802-.332z"
                                transform="rotate(45 30.474 34.474)"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <!-- #END# Card of Form -->
        </div>
        <div class="right">
          <div class="img-container">
            <img src="@/assets/images/hero/home.svg" alt="Zext Hero"/>
          </div>
        </div>
      </div>
    </section>
    <!-- #END# Hero With Video -->

    <!-- Service Steps -->
    <section v-if="howToServeData.length > 0" class="service-steps pt-4 pb-4">
      <div class="container">
        <div class="main-title">How do we serve our customers?</div>
        <div class="main-text">The core technology to enable modern data integration and data management solutions.
        </div>
      </div>

      <div class="slider-container">
        <!-- Repeat Slider Item -->
        <div v-for="(item, index) in howToServeData" :key="index" class="slider-item">
          <div class="card">
            <div class="img-container">
              <img :src="item.jetpack_featured_media_url" :alt="item.title.rendered"/>
            </div>
            <div class="content-container">
              <div class="top">
                <div class="title">{{ item.title.rendered }}</div>
                <div class="text">{{ stripHtml(item.content.rendered) }}</div>
              </div>
<!--              <div class="bottom">-->
<!--                <a class="btn btn-primary" :href="`/content/${item.slug}`">-->
<!--                  <span>Details</span>-->
<!--                </a>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <!-- #END# Repeat Slider Item -->
      </div>
    </section>
    <!-- #END# Service Steps -->

    <!-- Data Market -->
<!--    <section v-if="dataSetList.length > 0" class="data-market">-->
<!--      <div class="container">-->
<!--        <div class="main-title">Data Market</div>-->
<!--        <div class="main-text">The core technology to enable modern data integration and data management solutions.-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="container">-->
<!--        <div class="slider-container">-->
<!--          <div v-for="(item, index) in dataSetList" :key="index" class="slider-item">-->
<!--            <div class="card">-->
<!--              <div class="header">-->
<!--                <div class="title">{{ item.title.rendered }}</div>-->
<!--                <div class="price-area">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="52" viewBox="0 0 22 52">-->
<!--                    <g fill="none" fill-rule="evenodd">-->
<!--                      <g fill="#2B2B2B">-->
<!--                        <path d="M-2.657 31H49l-.257 17.934c-.031 2.21-1.848 3.974-4.056 3.943-.542-.008-1.077-.126-1.571-.346l-18.66-8.315c-1.036-.462-2.22-.462-3.256 0L2.717 52.452c-2.018.9-4.382-.007-5.281-2.025-.237-.53-.355-1.105-.346-1.686L-2.657 31z"-->
<!--                              transform="translate(-13 -17) rotate(90 23 43)"/>-->
<!--                      </g>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                  <div class="price-content">-->
<!--                    <div class="price">{{ currencySignMapper[item.acf.currency] }}{{ item.acf.price }}</div>-->
<!--                    <div class="per">{{ item.acf.period }}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="body">-->
<!--                <div class="text">{{ stripHtml(item.content.rendered) }}</div>-->
<!--              </div>-->
<!--              <div class="footer">-->
<!--                <div class="button-container">-->
<!--                  <a class="btn btn-primary" :href="`/content/${item.slug}`">-->
<!--                    <span>Details</span>-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="slider-item">-->
<!--            <div class="card">-->
<!--              <div class="header">-->
<!--                <div class="title">Data Market</div>-->
<!--              </div>-->
<!--              <div class="body">-->
<!--                <div class="text">-->
<!--                  The Data Company Group Limited, is a product and services company which helps enterprises-->
<!--                  achieve their data ambitions. The Data Company Group Limited, is a product and services-->
<!--                  company-->
<!--                  which helps enterprises achieve their data ambitions.-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="footer">-->
<!--                <form class="form-data-market">-->
<!--                  <input type="email" placeholder="Data, market, developer" required/>-->
<!--                  <button class="btn btn-danger" type="submit">-->
<!--                    <span>Search</span>-->
<!--                  </button>-->
<!--                </form>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <!-- #END# Data Market -->

    <!-- Blog - Summary -->
<!--    <section v-if="blogList.length > 0" class="blog-summary">-->
<!--      <div class="container">-->
<!--        <div class="main-title">Zext Blog</div>-->
<!--        <div class="main-text">The core technology to enable modern data integration and data management solutions.-->
<!--        </div>-->

<!--        <div class="card">-->
<!--          <div class="img-container">-->
<!--            <img class="sm-blog-img" :src="blogList[0].jetpack_featured_media_url"/>-->
<!--          </div>-->
<!--          <div class="other-container">-->
<!--            <div class="content-container">-->
<!--              <div class="title">{{ blogList[0].title.rendered }}</div>-->
<!--              <div class="text">{{ stripHtml(blogList[0].content.rendered) }}</div>-->
<!--            </div>-->
<!--            <div class="button-container">-->
<!--              <a class="btn btn-danger" :href="`/content/${blogList[0].slug}`">-->
<!--                <span>Go Blog</span>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <!-- #END# Blog - Summary -->

    <!-- What's Next -->
<!--    <section class="whats-next pt-4 pb-4">-->
<!--      <div class="container">-->
<!--        <div class="card">-->
<!--          <div class="main-text">What’s Next?</div>-->
<!--          <div class="text">Gain real-time insights from your data and begin your digital transformation today!</div>-->
<!--          <a class="btn btn-danger" href="#">-->
<!--            <span>Get Started</span>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <!-- #END# What's Next -->

    <!-- Testimonials -->
    <section v-if="testimonials.length > 0" class="testimonials testimonials-bottom-brand">
      <div class="container">
        <div class="main-title">Testimonials</div>
        <div class="main-text">The core technology to enable modern data integration and data management solutions.
        </div>

        <div class="slider-container">
          <!-- Slider Repeat Item -->
          <div v-for="(item, index) in testimonials" :key="index" class="slider-item">
            <div class="card testimonials-theme-pale-beach">
              <div class="img-container">
                <img :src="item.jetpack_featured_media_url"
                     :alt="`${item.name} - ${item.jobTitle}`"/>
              </div>
              <div class="content-container">
                <div class="person-brand-container">
                  <div class="person-container">
                    <div class="person">{{ item.name }}</div>
                    <div class="person-title">{{ item.jobTitle }}</div>
                  </div>
                  <div class="brand-container">
                    <img :src="item.acf.companyLogo" width="147"
                         :alt="item.acf.companyName"/>
                  </div>
                </div>
                <div class="text-container">
                  <div class="start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25" viewBox="0 0 32 25">
                      <g fill="none" fill-rule="evenodd">
                        <g fill="#BC8F5C" fill-rule="nonzero">
                          <path d="M787.297 3770.844c.875 0 1.695-.149 2.46-.446.766-.296 1.438-.695 2.016-1.195.579-.5 1.04-1.094 1.383-1.781.344-.688.516-1.438.516-2.25 0-.75-.125-1.453-.375-2.11-.25-.656-.586-1.218-1.008-1.687-.422-.469-.906-.836-1.453-1.102-.547-.265-1.133-.398-1.758-.398-.594 0-1.07.078-1.43.234-.359.157-.68.329-.96.516-.282.188-.555.36-.82.516-.266.156-.602.234-1.009.234-.375 0-.703-.258-.984-.773-.281-.516-.422-1.118-.422-1.805 0-1.156.266-2.281.797-3.375.531-1.094 1.258-2.117 2.18-3.07.922-.954 2-1.79 3.234-2.508 1.234-.719 2.57-1.282 4.008-1.688V3746c-1.969.125-3.844.586-5.625 1.383-1.781.797-3.336 1.86-4.664 3.187-1.328 1.328-2.39 2.883-3.188 4.664-.797 1.782-1.195 3.735-1.195 5.86 0 1.343.219 2.601.656 3.773.438 1.172 1.032 2.203 1.782 3.094.75.89 1.625 1.594 2.625 2.11 1 .515 2.078.773 3.234.773zm16.922 0c.875 0 1.703-.149 2.484-.446.781-.296 1.461-.695 2.04-1.195.577-.5 1.03-1.094 1.359-1.781.328-.688.492-1.438.492-2.25 0-.75-.125-1.453-.375-2.11-.25-.656-.586-1.218-1.008-1.687-.422-.469-.906-.836-1.453-1.102-.547-.265-1.133-.398-1.758-.398-.594 0-1.07.078-1.43.234-.36.157-.672.329-.937.516-.266.188-.531.36-.797.516-.266.156-.602.234-1.008.234s-.742-.258-1.008-.773c-.265-.516-.398-1.118-.398-1.805 0-1.156.265-2.281.797-3.375.531-1.094 1.25-2.117 2.156-3.07.906-.954 1.984-1.79 3.234-2.508 1.25-.719 2.578-1.282 3.985-1.688V3746c-1.969.125-3.836.586-5.602 1.383-1.765.797-3.32 1.86-4.664 3.187-1.344 1.328-2.414 2.883-3.21 4.664-.798 1.782-1.196 3.735-1.196 5.86 0 1.343.219 2.601.656 3.773.438 1.172 1.031 2.203 1.781 3.094.75.89 1.625 1.594 2.625 2.11 1 .515 2.078.773 3.235.773z"
                                transform="translate(-779 -3746)"/>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="text">{{ stripHtml(item.content.rendered) }}</div>
                  <div class="end">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25" viewBox="0 0 32 25">
                      <g fill="none" fill-rule="evenodd">
                        <g fill="#BC8F5C" fill-rule="nonzero">
                          <path d="M1288.297 3847.844c.875 0 1.695-.149 2.46-.446.766-.296 1.438-.695 2.016-1.195.579-.5 1.04-1.094 1.383-1.781.344-.688.516-1.438.516-2.25 0-.75-.125-1.453-.375-2.11-.25-.656-.586-1.218-1.008-1.687-.422-.469-.906-.836-1.453-1.102-.547-.265-1.133-.398-1.758-.398-.594 0-1.07.078-1.43.234-.359.157-.68.329-.96.516-.282.188-.555.36-.82.516-.266.156-.602.234-1.009.234-.375 0-.703-.258-.984-.773-.281-.516-.422-1.118-.422-1.805 0-1.156.266-2.281.797-3.375.531-1.094 1.258-2.117 2.18-3.07.922-.954 2-1.79 3.234-2.508 1.234-.719 2.57-1.282 4.008-1.688V3823c-1.969.125-3.844.586-5.625 1.383-1.781.797-3.336 1.86-4.664 3.187-1.328 1.328-2.39 2.883-3.188 4.664-.797 1.782-1.195 3.735-1.195 5.86 0 1.343.219 2.601.656 3.773.438 1.172 1.032 2.203 1.782 3.094.75.89 1.624 1.594 2.624 2.11 1 .515 2.079.773 3.235.773zm16.922 0c.875 0 1.703-.149 2.484-.446.781-.296 1.461-.695 2.04-1.195.577-.5 1.03-1.094 1.359-1.781.328-.688.492-1.438.492-2.25 0-.75-.125-1.453-.375-2.11-.25-.656-.586-1.218-1.008-1.687-.422-.469-.906-.836-1.453-1.102-.547-.265-1.133-.398-1.758-.398-.594 0-1.07.078-1.43.234-.36.157-.672.329-.937.516-.266.188-.531.36-.797.516-.266.156-.602.234-1.008.234s-.742-.258-1.008-.773c-.265-.516-.398-1.118-.398-1.805 0-1.156.265-2.281.797-3.375.531-1.094 1.25-2.117 2.156-3.07.906-.954 1.984-1.79 3.234-2.508 1.25-.719 2.579-1.282 3.985-1.688V3823c-1.969.125-3.836.586-5.602 1.383-1.765.797-3.32 1.86-4.664 3.187-1.344 1.328-2.414 2.883-3.21 4.664-.798 1.782-1.196 3.735-1.196 5.86 0 1.343.219 2.601.656 3.773.438 1.172 1.031 2.203 1.781 3.094.75.89 1.625 1.594 2.625 2.11 1 .515 2.079.773 3.235.773z"
                                transform="translate(-1280 -3823) rotate(180 1295.797 3835.422)"/>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- #END# Slider Repeat Item -->
        </div>
      </div>
    </section>
    <!-- #END# Testimonials -->

    <!-- Make Schedule -->
    <MakeSchedule />
    <!-- #END# Make Schedule -->
  </div>
</template>
<script>
import { mapState } from 'vuex';
import siteJs from '@/assets/js/site';
import MakeSchedule from '@/components/MakeSchedule.vue';

export default {
  name: 'Home',
  components: { MakeSchedule },
  computed: {
    ...mapState({
      headline: (state) => state.homePage.headline,
      blogList: (state) => state.homePage.blogList,
      howToServeData: (state) => state.homePage.howToServeData.reverse(),
      dataSetList: (state) => state.homePage.dataSetList,
      testimonials: (state) => state.homePage.testimonials,
      currencySignMapper: (state) => state.currencySignMapper,
    }),
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('homePage/getHeadline'),
      this.$store.dispatch('homePage/getBlogList'),
      this.$store.dispatch('homePage/getDataSetList'),
      this.$store.dispatch('homePage/getHowToServeData'),
      this.$store.dispatch('homePage/getTestimonials'),
    ]);
    siteJs();
  },
};
</script>
