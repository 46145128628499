<template>
  <div>
    <section v-if="Object.keys(contentDetail).length > 0" class="content mt-4">
      <div class="container">
        <div class="card">
          <div v-html="contentDetail.content.rendered"></div>
        </div>
      </div>
    </section>

    <MakeSchedule v-if="loading === false"></MakeSchedule>
  </div>
</template>

<script>
import MakeSchedule from '@/components/MakeSchedule.vue';
import siteJs from '@/assets/js/site';
import { mapState } from 'vuex';

export default {
  name: 'PageDetail',
  components: { MakeSchedule },
  computed: {
    ...mapState({
      contentDetail: (state) => state.contentDetail,
    }),
  },
  data: () => ({
    loading: true,
  }),
  beforeCreate() {
    $('body').addClass('page-solution-detail');
  },
  created() {
    this.$store.dispatch('getPageDetail', this.$route.params.name).then(() => {
      siteJs();
      this.loading = false;
      this.setPageTitle(this.contentDetail.title.rendered);
    }).catch(() => {
      this.$router.push({ path: '/404' });
    });
  },
};
</script>

<style scoped>

</style>
