import Vue from 'vue';
import Vuex from 'vuex';
import HttpService from '@/services/http-service';
import DataDecorator from '@/helper/dataDecorator';
import dataMarket from '@/store/modules/dataMarket';
import solution from '@/store/modules/solution';
import caseStudy from '@/store/modules/caseStudy';
import resources from '@/store/modules/resources';
import pricing from '@/store/modules/pricing';
import company from '@/store/modules/company';
import homePage from '@/store/modules/homePage';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    headerMenu: [
      {
        name: 'Solution',
        path: '/solution',
        sub: [
        //   {
        //     name: 'Solution Intro',
        //     path: 'solution/intro',
        //   },
        //   {
        //     name: 'Features List',
        //     path: '#',
        //   },
        //   {
        //     name: 'Features Detail',
        //     path: '#',
        //   },
        ],
      },
      // {
      //   name: 'Case Studies',
      //   path: '/case-studies',
      //   sub: [
      //     {
      //       name: 'Case Study List',
      //       path: 'case-studies/list',
      //     },
      //     {
      //       name: 'Case Study Detail',
      //       path: '#',
      //     },
      //   ],
      // },
      // {
      //   name: 'Resources',
      //   path: '/resources',
      //   sub: [
      //     // {
      //     //   name: 'Resources List',
      //     //   path: 'resources/list',
      //     // },
      //     // {
      //     //   name: 'Documentation',
      //     //   path: '#',
      //     // },
      //     // {
      //     //   name: 'Article Single',
      //     //   path: '#',
      //     // },
      //     // {
      //     //   name: 'Support',
      //     //   path: '#',
      //     // },
      //     // {
      //     //   name: 'API',
      //     //   path: '#',
      //     // },
      //     // {
      //     //   name: 'White Papers',
      //     //   path: '#',
      //     // },
      //   ],
      // },
      // {
      //   name: 'Data Market',
      //   path: '#',
      //   sub: [
      //     {
      //       name: 'Dataset List',
      //       path: 'data-market',
      //     },
      //     {
      //       name: 'Dataset Detail',
      //       path: '#',
      //     },
      //   ],
      // },
      {
        name: 'About',
        path: '/company/about',
        sub: [
          // {
          //   name: 'About',
          //   path: 'company/about',
          // },
          // {
          //   name: 'Partners',
          //   path: '#',
          // },
          // {
          //   name: 'Schedule a Call',
          //   path: '#',
          // },
          // {
          //   name: 'Career List',
          //   path: '#',
          // },
          // {
          //   name: 'Career Detail',
          //   path: '#',
          // },
          // {
          //   name: 'Reviews',
          //   path: '#',
          // },
        ],
      },
      // {
      //   name: 'Pricing',
      //   path: 'pricing',
      //   sub: [],
      // },
    ],
    currencySignMapper: {
      USD: '$',
      EUR: '€',
    },
    categoryList: {},
    tagList: {},
    languageList: [],
    filterList: {
      solution: [],
      'solutions-by-scopes': [],
      'solutions-by-departments': [],
      'solutions-by-source-types': [],
    },
    selectedLanguage: '',
    contentDetail: {},
  },
  mutations: {
    SET_CATEGORY_LIST: (state, data) => {
      state.categoryList = data;
    },
    SET_TAG_LIST: (state, data) => {
      state.tagList = data;
    },
    SET_LANGUAGE_LIST: (state, data) => {
      state.languageList = data;
    },
    SET_SELECTED_LANGUAGE: (state, data) => {
      state.selectedLanguage = data;
    },
    SET_FILTER_LIST: (state, data) => {
      state.filterList = data;
    },
    SET_CONTENT_DETAIL: (state, data) => {
      state.contentDetail = data;
    },
  },
  getters: {
    categoryList(state) {
      return state.categoryList;
    },
    tagList(state) {
      return state.tagList;
    },
    languageList(state) {
      return state.languageList;
    },
    selectedLanguage(state) {
      return state.selectedLanguage;
    },
    filterList(state) {
      return state.filterList;
    },
    contentDetail(state) {
      return state.contentDetail;
    },
  },
  actions: {
    getHomeHeadline({ getters }, data) {
      const params = {
        categories: getters.categoryList[data.category].id,
        tags: `${getters.tagList[data.tag].id}&${getters.languageList[getters.selectedLanguage].id}`,
      };

      return HttpService.get(`${process.env.VUE_APP_WP_API_URL}/posts?categories=${params.categories}&tags=${params.tags}`, {})
        .then((res) => res);
    },
    getDataByPageAndTag({ getters }, data) {
      const params = {
        categories: getters.categoryList[data.category].id,
        tags: `${getters.tagList[data.tag].id}&${getters.languageList[getters.selectedLanguage].id}`,
      };

      return HttpService.get(`${process.env.VUE_APP_WP_API_URL}/posts?categories=${params.categories}&tags=${params.tags}`, {})
        .then((res) => res);
    },
    getContentDetail({ commit }, data) {
      return HttpService.get(`${process.env.VUE_APP_WP_API_URL}/posts?slug=${data}`, {})
        .then((res) => {
          if (res.length > 0) {
            commit('SET_CONTENT_DETAIL', res[0]);
          } else {
            throw new Error('No content 404');
          }
        });
    },
    getPageDetail({ commit }, data) {
      return HttpService.get(`${process.env.VUE_APP_WP_API_URL}/pages?slug=${data}`, {})
        .then((res) => {
          if (res.length > 0) {
            commit('SET_CONTENT_DETAIL', res[0]);
          } else {
            throw new Error('No content 404');
          }
        });
    },
    getPostCategories({ commit, getters }, data) {
      return HttpService.get(`${process.env.VUE_APP_WP_API_URL}/categories?per_page=100`, data)
        .then((res) => {
          commit('SET_FILTER_LIST', DataDecorator.decorateFilterList(res, getters.filterList));
          commit('SET_CATEGORY_LIST', DataDecorator.decorateWordpressCommonList(res));
        });
    },
    getPostTags({ commit }, data) {
      return HttpService.get(`${process.env.VUE_APP_WP_API_URL}/tags?per_page=100`, data)
        .then((res) => {
          commit('SET_TAG_LIST', DataDecorator.decorateWordpressCommonList(res));
          commit('SET_LANGUAGE_LIST', DataDecorator.decorateLanguageList(res));

          if (typeof Vue.prototype.$session.get('lang') === 'undefined') {
            commit('SET_SELECTED_LANGUAGE', 'lang-en');
          } else {
            commit('SET_SELECTED_LANGUAGE', Vue.prototype.$session.get('lang'));
          }
        });
    },
  },
  modules: {
    dataMarket,
    homePage,
    solution,
    caseStudy,
    resources,
    pricing,
    company,
  },
});
