export default {
  namespaced: true,
  state: {
    category: 'case-studies',
    leaderBoard: {},
    caseStudyList: [],
    promotionText: {},
  },
  getters: {
    leaderBoard(state) {
      return state.leaderBoard;
    },
    promotionText(state) {
      return state.promotionText;
    },
    category(state) {
      return state.category;
    },
    caseStudyList(state) {
      return state.caseStudyList;
    },
  },
  actions: {
    getLeaderBoard({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'leaderboard' }, { root: true })
        .then((res) => {
          if (res.length > 0) {
            commit('SET_LEADERBOARD', res[0]);
          }
        })
        .catch();
    },
    getPromotionText({ commit, getters }, data) {
      const category = typeof data === 'undefined' ? getters.category : data;

      return this.dispatch('getDataByPageAndTag', { category, tag: 'promotion-text' }, { root: true })
        .then((res) => {
          if (res.length > 0) {
            commit('SET_PROMOTION_TEXT', res[0]);
          }
        })
        .catch();
    },
    getCaseStudyList({ commit, getters }, data) {
      const category = typeof data === 'undefined' ? getters.category : data;

      return this.dispatch('getDataByPageAndTag', { category, tag: 'case-study-list' }, { root: true })
        .then((res) => {
          commit('SET_CASE_STUDY_LIST', res);
        })
        .catch();
    },
  },
  mutations: {
    SET_LEADERBOARD: (state, data) => {
      state.leaderBoard = data;
    },
    SET_PROMOTION_TEXT: (state, data) => {
      state.promotionText = data;
    },
    SET_CASE_STUDY_LIST: (state, data) => {
      state.caseStudyList = data;
    },
  },
};
