<template>
  <div>
    <PageIntro v-if="Object.keys(leaderBoard).length > 0" :filterActive="false" :backgroundClass="'bg-col-light-blue-grey'" :data="leaderBoard"></PageIntro>

    <section v-if="Object.keys(companyInfo).length > 0" class="our-company">
      <div class="top-container">
        <div class="container">
          <div class="header">
<!--            <div class="title">{{ companyInfo.title.rendered }}</div>-->
            <div class="text">{{ stripHtml(companyInfo.content.rendered) }}</div>
          </div>
          <div class="body">
            <div class="left">
              <div class="title">Mission</div>
              <div class="text">{{ companyInfo.acf.mission_text }}</div>
            </div>
            <div class="right">
              <div class="title">Vision</div>
              <div class="text">{{ companyInfo.acf.vision_text }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer" v-if="companyInfo.acf.total_download != ''">
        <div class="sub-container">
          <img src="@/assets/images/about/about_circular.png" alt="Zext - About"/>
          <ul>
            <li>
              <span>{{ companyInfo.acf.total_download }}</span>
              <span>Downloads</span>
            </li>
            <li>
              <span>{{ companyInfo.acf.total_community_members }}</span>
              <span>Community Members</span>
            </li>
            <li>
              <span>{{ companyInfo.acf.total_enterprise_customers }}</span>
              <span>Enterprise Customers</span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section v-if="Object.keys(companyInfo).length > 0" class="our-story">
      <div class="container">
        <div class="title">Our Story</div>
        <div class="text">{{ companyInfo.acf.story_text }}</div>
        <img :src="companyInfo.acf.company_photo.url"
             alt="Zext - Company"/>
      </div>
    </section>

<!--    <section v-if="Object.keys(companyInfo).length > 0" class="ceo-message">-->
<!--      <div class="container">-->
<!--        <div class="title">CEO Message</div>-->
<!--        <div class="text">Powering all APIs and microservices for today’s leading innovators.</div>-->

<!--        <div class="card">-->
<!--          <img :src="companyInfo.acf.ceo_image" :alt="companyInfo.acf.ceo_name"/>-->
<!--          <div class="content">-->
<!--            <div class="header">-->
<!--              <div class="left">-->
<!--                <div class="title">{{ companyInfo.acf.ceo_name }}</div>-->
<!--                <div class="user-title">CEO</div>-->
<!--              </div>-->
<!--              <div class="right">-->
<!--                <ul>-->
<!--                  <li v-if="companyInfo.acf.ceo_crunchbase_url.length > 0">-->
<!--                    <a :href="companyInfo.acf.ceo_crunchbase_url" title="Crunchbase" target="_blank">-->
<!--                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26"-->
<!--                           viewBox="0 0 25.8 25.8">-->
<!--                        <path d="M26.8,4.4a3.2,3.2,0,0,0-3.2-3.2H4.4A3.2,3.2,0,0,0,1.2,4.4V23.6a3.2,3.2,0,0,0,3.2,3.2H23.6a3.2,3.2,0,0,0,3.2-3.2V4.4Z"-->
<!--                              transform="translate(-1 -1)" style="fill:#0a4063"/>-->
<!--                        <path d="M10.68,15.89a2.82,2.82,0,1,1,0-2.33H12.9a4.91,4.91,0,1,0,0,2.33H10.68Zm9.13-6.11H19.45a4.86,4.86,0,0,0-2.41.85V5.14H15V19.26h2V18.74A4.93,4.93,0,1,0,19.81,9.78Zm2.83,5.28v0.09a2.87,2.87,0,0,1-.08.35,2.61,2.61,0,0,1-.14.36v0a2.83,2.83,0,0,1-2,1.55l-0.27,0H19.81l-0.38,0H19.34a2.74,2.74,0,0,1-.72-0.22H18.57a2.8,2.8,0,0,1-.64-0.43,2.88,2.88,0,0,1-.5-0.6,2.76,2.76,0,0,1-.18-0.35,2.82,2.82,0,0,1,2.3-3.93h0.29a2.83,2.83,0,0,1,2.79,2.75,2.65,2.65,0,0,1,0,.38h0Z"-->
<!--                              transform="translate(-1 -1)" style="fill:#fff"/>-->
<!--                        <path d="M26.6,4.2A3.2,3.2,0,0,0,23.4,1H4.2A3.2,3.2,0,0,0,1,4.2V23.4a3.2,3.2,0,0,0,3.2,3.2H23.4a3.2,3.2,0,0,0,3.2-3.2V4.2Z"-->
<!--                              transform="translate(-1 -1)"/>-->
<!--                        <path d="M11,16a2.63,2.63,0,1,1,0-2.17h2A4.57,4.57,0,1,0,13,16H11Zm7.57-5.68H18.21a4.54,4.54,0,0,0-2.24.79V6H14.11V19.14H16V18.66a4.59,4.59,0,1,0,2.57-8.34h0Zm2.63,4.92v0.08a2.52,2.52,0,0,1-.07.32A2.38,2.38,0,0,1,21,16v0a2.64,2.64,0,0,1-1.84,1.44l-0.25,0H18.55l-0.36,0H18.11a2.62,2.62,0,0,1-.67-0.2H17.39a2.54,2.54,0,0,1-1.06-1,2.63,2.63,0,0,1,2-4h0.27a2.64,2.64,0,0,1,2.6,2.56,2.58,2.58,0,0,1,0,.35h0Z"-->
<!--                              transform="translate(-1 -1)" style="fill:#fff"/>-->
<!--                      </svg>-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <li v-if="companyInfo.acf.ceo_linkedin_url.length > 0">-->
<!--                    <a :href="companyInfo.acf.ceo_linkedin_url" title="LinkedIn" target="_blank">-->
<!--                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--                           width="26" height="26" viewBox="0 0 26 26">-->
<!--                        <defs>-->
<!--                          <path id="jfm6nljd0a" d="M0 0L25.92 0 25.92 25.909 0 25.909z"/>-->
<!--                        </defs>-->
<!--                        <g fill="none" fill-rule="evenodd">-->
<!--                          <g>-->
<!--                            <g>-->
<!--                              <g transform="translate(-1418 -2840) translate(1418 2840)">-->
<!--                                <mask id="7w40zugugb" fill="#fff">-->
<!--                                  <use xlink:href="#jfm6nljd0a"/>-->
<!--                                </mask>-->
<!--                                <path fill="#000"-->
<!--                                      d="M24.002 0H1.913C.858 0 0 .837 0 1.868V24.05c0 1.032.858 1.87 1.913 1.87h22.089c1.057 0 1.918-.838 1.918-1.87V1.868C25.92.837 25.06 0 24.002 0"-->
<!--                                      mask="url(#7w40zugugb)"/>-->
<!--                              </g>-->
<!--                              <path fill="#FFFFFE"-->
<!--                                    d="M5.766 3.568c1.23 0 2.228 1 2.228 2.229 0 1.23-.999 2.23-2.228 2.23-1.232 0-2.23-1-2.23-2.23s.998-2.229 2.23-2.229zm-1.923 18.52h3.845V9.717H3.843v12.37zM10.099 9.717h3.688v1.69h.05c.514-.972 1.768-1.997 3.638-1.997 3.893 0 4.612 2.562 4.612 5.892v6.785h-3.842v-6.015c0-1.435-.026-3.28-1.997-3.28-2.001 0-2.308 1.562-2.308 3.176v6.12H10.1V9.717"-->
<!--                                    transform="translate(-1418 -2840) translate(1418 2840)"/>-->
<!--                            </g>-->
<!--                          </g>-->
<!--                        </g>-->
<!--                      </svg>-->
<!--                    </a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="body">{{ companyInfo.acf.ceo_message }}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

    <MakeSchedule></MakeSchedule>
  </div>
</template>

<script>
import MakeSchedule from '@/components/MakeSchedule.vue';
import siteJs from '@/assets/js/site';
import PageIntro from '@/components/PageIntro.vue';
import { mapState } from 'vuex';

export default {
  name: 'Company',
  components: { PageIntro, MakeSchedule },
  computed: {
    ...mapState({
      leaderBoard: (state) => state.company.leaderBoard,
      companyInfo: (state) => state.company.companyInfo,
    }),
  },
  beforeCreate() {
    $('body').addClass('page-company');
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('company/getLeaderBoard'),
      this.$store.dispatch('company/getCompanyInfo'),
    ]);
    this.setPageTitle(this.companyInfo.title.rendered);
    siteJs();
  },
};
</script>

<style scoped>

</style>
