const decorator = {
  decorateWordpressCommonList(data) {
    const decoratedData = {};
    data.forEach((item) => {
      decoratedData[item.slug] = {
        id: item.id,
      };
    });

    return decoratedData;
  },
  decorateLanguageList(data) {
    const decoratedData = {};
    data.forEach((item) => {
      if (item.slug.indexOf('lang') >= 0) {
        decoratedData[item.slug] = {
          id: item.id,
          name: item.name,
        };
      }
    });

    return decoratedData;
  },
  decorateFilterList(data, filters) {
    const list = {};
    const detailedListChildren = {};
    const decoratedData = {};

    data.forEach((item) => {
      list[item.slug] = {
        id: item.id,
      };

      if (item.parent > 0) {
        if (typeof detailedListChildren[item.parent] !== 'undefined') {
          detailedListChildren[item.parent].children.push({
            id: item.id,
            name: item.name.replace('&amp;', '&'),
            slug: item.slug,
          });
        } else {
          detailedListChildren[item.parent] = {
            children: [
              {
                id: item.id,
                name: item.name.replace('&amp;', '&'),
                slug: item.slug,
              },
            ],
          };
        }
      } else if (item.parent === 0 && typeof detailedListChildren[item.id] === 'undefined') {
        detailedListChildren[item.id] = {
          children: [],
        };
      }
    });

    Object.keys(filters).forEach((item) => {
      decoratedData[item] = detailedListChildren[list[item].id].children;
    });

    return decoratedData;
  },
};

export default decorator;
