export default {
  methods: {
    stripHtml(data) {
      return this.decodeHTMLEntities(data.replace(/<[^>]+>/g, ''));
    },
    decodeHTMLEntities(text) {
      const entities = {
        amp: '&',
        apos: '\'',
        '#x27': '\'',
        '#x2F': '/',
        '#39': '\'',
        '#47': '/',
        lt: '<',
        gt: '>',
        nbsp: ' ',
        quot: '"',
        '#8217': '\'',
      };

      return text.replace(/&([^;]+);/gm, (match, entity) => entities[entity] || match);
    },
    substringWithChar(data, char, limit) {
      const stripped = this.stripHtml(data);
      const firstIndex = stripped.indexOf(char, limit);
      return stripped.substring(0, firstIndex + 1);
    },
    setPageTitle(title) {
      document.title = `${title} | Zext`;
    },
  },
};
