<template>
  <div>
    <PageIntro v-if="Object.keys(leaderBoard).length > 0"
               @filter-changed="onFilterChanged"
               :filterActive="true"
               :backgroundClass="'bg-col-light-blue'"
               :data="leaderBoard"></PageIntro>

    <!-- Dataset List & Modal -->
    <section class="dataset-list">
      <div class="header-container">
        <div class="container">
          <div class="header">
            <div class="title">Zext Data Market</div>
            <div class="text">
              Test Text ; Kong makes connecting APIs and microservices across today’s hybrid, multi-cloud
              environments
              easier and faster than ever. We power trillions of API transactions for leading organizations
              globally
              through our end-to-end API platform.
            </div>
            <form class="form-dataset-search">
              <div class="form-container">
                <input type="text" placeholder="Data, Market, Developer" required/>
                <button class="btn btn-danger" type="submit">
                  <span>Search</span>
                </button>
              </div>
            </form>

            <div class="featured-products-container">
              <div class="title">Featured products</div>

              <div class="select-container">
                <select></select>
              </div>

              <ul class="product-list">
                <li class="active">
                  <a data-key="overview" href="#">Overview</a>
                </li>
                <li>
                  <a data-key="featured-products" href="#">Featured products</a>
                </li>
                <li>
                  <a data-key="ai-machine-learning" href="#">AI and Machine Learning</a>
                </li>
                <li>
                  <a data-key="bigquery" href="#">BigQuary</a>
                </li>
                <li>
                  <a data-key="security-identity" href="#">Security & Identity</a>
                </li>
                <li>
                  <a data-key="compute" href="#">Compute</a>
                </li>
                <li>
                  <a data-key="containers" href="#">Containers</a>
                </li>
                <li>
                  <a data-key="data-analytics" href="#">Data Analytics</a>
                </li>
                <li>
                  <a data-key="databases" href="#">Databases</a>
                </li>
                <li>
                  <a data-key="developer-tools" href="#">Developer Tools</a>
                </li>
                <li>
                  <a data-key="healthcare-life-sciences" href="#">Healthcare and Life Sciences</a>
                </li>
                <li>
                  <a data-key="hybrid-multi-cloud" href="#">Hybrid and Multi-cloud</a>
                </li>
                <li>
                  <a data-key="iot" href="#">Internet of Things (IoT)</a>
                </li>
                <li>
                  <a data-key="management-tools" href="#">Management Tools</a>
                </li>
                <li>
                  <a data-key="media-gaming" href="#">Media and Gaming</a>
                </li>
                <li>
                  <a data-key="serverless-computing" href="#">Serverless Computing</a>
                </li>
                <li>
                  <a data-key="storage" href="#">Storage</a>
                </li>
                <li>
                  <a data-key="more-google-cloud-products" href="#">More Google Cloud products</a>
                </li>
                <li>
                  <a data-key="product-launch-stages" href="#">Product launch stages</a>
                </li>
                <li>
                  <a data-key="take-next-step" href="#">Take the next step</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div ref="filter-result" id="filter-result" v-if="dataSetList.length > 0" class="body-container">
        <div class="container">
          <div class="body">
            <div class="results">
              <div v-for="(item, index) in dataSetList" :key="index" class="card-v2">
                <div class="card-header">
                  <div class="title">{{ item.title.rendered }}</div>
                  <div class="price-area">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.32 26">
                      <path d="M11.4,25.75H2.07a1.75,1.75,0,0,1-1.6-2.46h0l4.15-9.38a2.25,2.25,0,0,0,0-1.82h0L0.47,2.71A1.75,1.75,0,0,1,2.07.25H11.4"
                            transform="translate(-0.07 0)"
                            style="fill:#fafafa;stroke:#979797;stroke-width:0.5px"/>
                    </svg>
                    <div class="price-content">
                      <div class="price">{{ currencySignMapper[item.acf.currency] }}{{ item.acf.price }}</div>
                      <div class="per">{{ item.acf.period }}</div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="text">{{ stripHtml(item.content.rendered) }}</div>
                </div>
                <div class="card-footer">
                  <button @click="selectDataSetItem(item)" class="btn btn-transparent">Details</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Dataset Modal -->
      <div class="modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="header">
              <div class="left">
                <div class="price-area">
                  <div class="price-content">
                    <div class="price">{{ currencySignMapper[selectedDataSetItem.acf.currency] }}{{ selectedDataSetItem.acf.price }}</div>
                    <div class="per">{{ selectedDataSetItem.acf.period }}</div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 52">
                    <g fill="none" fill-rule="evenodd">
                      <g fill="#000">
                        <path d="M-2.657 31H49l-.257 17.934c-.031 2.21-1.848 3.974-4.056 3.943-.542-.008-1.077-.126-1.571-.346l-18.66-8.315c-1.036-.462-2.22-.462-3.256 0L2.717 52.452c-2.018.9-4.382-.007-5.281-2.025-.237-.53-.355-1.105-.346-1.686L-2.657 31z"
                              transform="translate(-13 -17) rotate(90 23 43)"/>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="right">
                <div class="close">&nbsp;</div>
              </div>
            </div>
            <div class="body">
              <div class="title">{{ selectedDataSetItem.title.rendered }}</div>
              <div class="content">
                <p>{{ stripHtml(selectedDataSetItem.content.rendered) }}</p>
              </div>
            </div>
            <div class="footer">
              <div class="left">
                <div class="info">Please contact our sales representative if you have any questions.</div>
              </div>
              <div class="right">
                <button class="btn btn-danger">
                  <span>{{ selectedDataSetItem.title.rendered }} Buy</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay">&nbsp;</div>
    </section>

    <RequestDemo></RequestDemo>
    <MakeSchedule></MakeSchedule>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import siteJs from '@/assets/js/site';
import RequestDemo from '@/components/RequestDemo.vue';
import MakeSchedule from '@/components/MakeSchedule.vue';
import PageIntro from '@/components/PageIntro.vue';

export default {
  name: 'DataMarket',
  components: { PageIntro, MakeSchedule, RequestDemo },
  data() {
    return {
      selectedDataSetItem: {
        acf: {
          currency: '',
          price: '',
          period: '',
        },
        title: {
          rendered: '',
        },
        content: {
          rendered: '',
        },
      },
    };
  },
  computed: {
    ...mapState({
      leaderBoard: (state) => state.dataMarket.leaderBoard,
      dataSetList: (state) => state.dataMarket.dataSetList,
      currencySignMapper: (state) => state.currencySignMapper,
    }),
  },
  beforeCreate() {
    $('body').addClass('page-data-market');
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('dataMarket/getLeaderBoard'),
      this.$store.dispatch('dataMarket/getDataSetList'),
    ]);
    siteJs();
  },
  methods: {
    selectDataSetItem(item) {
      this.selectedDataSetItem = item;
    },
    async onFilterChanged(data) {
      if (data.length > 0) {
        await this.$store.dispatch('dataMarket/getDataSetList', data);
        const scrollItem = this.$refs['filter-result'];
        if (scrollItem) {
          window.scroll({ top: (scrollItem.offsetTop - 100), left: 0, behavior: 'smooth' });
        }
      } else {
        await this.$store.dispatch('dataMarket/getDataSetList');
      }
    },
  },
};
</script>

<style scoped>

</style>
