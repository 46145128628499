<template>
  <div>
    <section class="content mt-4 mb-4">
      <div class="container">
        <div class="card">
          <p class="text-center big-title">404 - Page not found</p>
          <img class="d-block m-a" src="@/assets/images/icons/electronic-panel.svg" height="250" alt="Zext Preloader" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import siteJs from '@/assets/js/site';

export default {
  name: 'PageNotFound.vue',
  mounted() {
    document.title = '404 - Not Found | Zext';
  },
  async created() {
    siteJs();
  },
};
</script>

<style scoped>

</style>
