import 'slick-carousel/slick/slick.min';
import 'malihu-custom-scrollbar-plugin';

const siteJs = (() => {
  $.Zext = {};

  /* Options =========================================================================================================
    *  You can manage the all options */
  $.Zext.options = {
    selectors: {
      dropdown: {
        main: '.dropdown',
        toggle: '.dropdown .dropdown-toggle',
      },
      form: {
        headerSearch: 'header .form-header-search',
      },
      header: {
        main: 'header',
        search: {
          input: 'header .search-container .search-input',
          btnClose: 'header .search-container .close-search',
        },
        searchSuggestion: {
          linksContainer: 'header .header-links-container',
          list: 'header .term-suggestion-container .quick-term-list',
          listItem: 'header .term-suggestion-container .quick-term-list .list-item',
        },
        toggle: {
          main: 'header .logo-toggle-container .navbar-icon-toggle',
          search: 'header .logo-toggle-container .navbar-icon-search',
          close: 'header .logo-toggle-container .navbar-icon-close',
        },
      },
      hero: {
        video: {
          main: '.hero-with-video',
          video: '.hero-with-video .video-container video',
          closeBtn: '.hero-with-video .video-container .close-video',
          source: '.hero-with-video .video-container video source',
          btnPlay: '.hero-with-video .pricing-calc-container .js-play-video',
        },
      },
      testimonials: {
        main: '.testimonials',
        slider: '.testimonials .slider-container',
      },
      serviceSteps: {
        main: '.service-steps',
        slider: '.service-steps .slider-container',
      },
      dataMarket: {
        main: '.data-market',
        slider: '.data-market .slider-container',
      },
      datasetList: {
        featuredList: '.dataset-list .product-list',
        featuredSelect: '.dataset-list .select-container select',
        buttonFeaturedItemDetail: '.dataset-list .card-v2 .card-footer .btn',
        modal: '.dataset-list .modal',
        btnModalClose: '.modal .close',
        modalBody: '.dataset-list .modal .modal-content .body',
        modalOverlay: '.dataset-list .modal-overlay',
      },
      introWithHalfImage: {
        main: '.intro-with-half-image',
        container: '.intro-with-half-image .intro-container',
        containerMobile: '.intro-with-half-image .intro-container-for-mobile',
        btnReadMore: '.intro-with-half-image .intro-container .read-more-icon svg, .intro-with-half-image '
          + '.intro-container-for-mobile .read-more-icon svg',
      },
      pricingWizard: {
        main: '.pricing-wizard',
        slider: '.pricing-wizard .slider-container',
        btnNext: '.pricing-wizard .slider-container .slider-item .card .footer .right .btn-next',
        btnFinish: '.pricing-wizard .slider-container .slider-item .card .footer .right .btn-finish',
        processColumn: '.pricing-wizard .navigation .card .process-column',
        navigationHorizontal: '.pricing-wizard .container .navigation',
      },
      preloader: {
        main: '.preloader',
      },
    },
    carousel: {
      prevArrow: '<button class="prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.09 26.45"><path d="M21.26,28.15l12-12Z" transform="translate(-20.21 -15.09)" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.1059999465942383px"/><path d="M21.26,28.48l12,12Z" transform="translate(-20.21 -15.09)" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.1059999465942383px"/></svg></button>',
      nextArrow: '<button class="next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.09 26.45"><path d="M35.74,28.15l-12-12Z" transform="translate(-22.7 -15.09)" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.1059999465942383px"/><path d="M35.74,28.48l-12,12Z" transform="translate(-22.7 -15.09)" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.1059999465942383px"/></svg></button>',
    },
    values: {
      dataMarketSliderStartWith: 992,
      minimizeHeaderPosition: 240,
      pricingWizardNavigationMinWidth: 200,
    },
    classNames: {
      body: {
        navbarOpened: 'navbar-opened',
      },
      header: {
        searchShow: 'search-show',
        minimize: 'minimize-header',
      },
      dropdown: {
        show: 'show',
        toggle: 'dropdown-toggle',
      },
      navbar: {
        show: 'navbar-show',
      },
      slider: {
        slickCarousel: 'slick-initialized',
      },
      introWithHalfImage: {
        readMore: 'read-more',
      },
      modal: {
        open: 'modal-open',
      },
    },
  };

  const { selectors } = $.Zext.options;
  const { carousel } = $.Zext.options;
  const { values } = $.Zext.options;
  const { classNames } = $.Zext.options;

  /* Common ==========================================================================================================
    *  You can manage common settings */
  $.Zext.common = {
    init() {
      const $this = this;

      // Used for detect mac&ios system and fix the font padding issue
      $this.initOperationSystem();

      // Hide preloader after 2 seconds
      setTimeout(() => {
        $.Zext.preloader.hide();
      }, 2000);
    },
    initOperationSystem() {
      const platform = navigator.platform.toLowerCase();
      let os = 'win';

      if (platform.indexOf('mac') > -1 || platform.indexOf('iphone') > -1) {
        os = 'mac';
      } else if (platform.indexOf('linux') > -1) {
        os = 'linux';
      }

      $('body').addClass(`page-home ${os}`);
    },
    getBrowserScrollWidth() {
      return window.innerWidth - document.documentElement.clientWidth;
    },
  };

  /* Preloader =======================================================================================================
    *  You can manage preloader settings */
  $.Zext.preloader = {
    init() {
      const $this = this;
      $this.show();
    },
    show() {
      if ($(selectors.preloader.main).hasClass('hidden')) $(selectors.preloader.main).removeClass('hidden');
    },
    hide() {
      if (!$(selectors.preloader.main).hasClass('hidden')) $(selectors.preloader.main).addClass('hidden');
    },
  };

  /* Header ==========================================================================================================
    *  You can manage the header options */
  $.Zext.header = {
    init() {
      const $this = this;
      $this.initSearch();
      $this.initNavbar();
      $this.minimizeMaximize();
    },
    initSearch() {
      // Search button close on click event
      $(selectors.header.search.btnClose).on('click', () => {
        $(selectors.header.main).removeClass(classNames.header.searchShow);
      });

      // Show search area on input or search icon on click event
      $(`${selectors.header.search.input}, ${selectors.header.toggle.search}`).on('click', () => {
        $(selectors.header.main).removeClass(classNames.navbar.show);
        $(selectors.header.main).addClass(classNames.header.searchShow);
        $(selectors.header.search.input).focus();
      });

      // Quick suggestion term on click event
      $(selectors.header.searchSuggestion.listItem).on('click', () => {
        const term = $(this).text();
        $(selectors.header.search.input).val(term);
        $(selectors.form.headerSearch).submit();
      });
    },
    initNavbar() {
      // Menu toggle icon on click event (when header toggled)
      $(selectors.header.toggle.main).on('click', () => {
        $(selectors.header.main).toggleClass(classNames.navbar.show);
        $('body').toggleClass(classNames.body.navbarOpened);
      });

      // Menu close icon on click event (when header toggled)
      $(selectors.header.toggle.close).on('click', () => {
        $(selectors.header.main).removeClass(classNames.navbar.show);
        $('body').removeClass(classNames.body.navbarOpened);
      });
    },
    minimizeMaximize() {
      $(window).scroll(() => {
        const scroll = $(window).scrollTop();
        if (scroll >= values.minimizeHeaderPosition) {
          $('body').addClass(classNames.header.minimize);
        } else {
          $('body').removeClass(classNames.header.minimize);
        }
      });
    },
  };

  /* Dropdown ========================================================================================================
    *  You can manage the dropdown options */
  $.Zext.dropdown = {
    init() {
      const $this = this;

      $this.toggleDropdown();
      // Close dropdown if outside click
      $('body').on('click', (e) => {
        if (
          $(e.target).parent(`.${classNames.dropdown.toggle}`).length === 0
          && $(e.target).parents(`.${classNames.dropdown.toggle}`).length === 0
          && !$(e.target).hasClass(classNames.dropdown.toggle)
        ) { $this.closeAllDropdown(); }
      });
    },
    toggleDropdown() {
      const $this = this;

      $(selectors.dropdown.toggle).on('click', (e) => {
        e.preventDefault();
        if ($(this).parent().hasClass(classNames.dropdown.show)) {
          $(this).parent().removeClass(classNames.dropdown.show);
        } else {
          $this.closeAllDropdown();
          $(e.target).parent('a').parent($(selectors.dropdown.main)).addClass(classNames.dropdown.show);
        }
      });
    },
    closeAllDropdown() {
      $(selectors.dropdown.main).removeClass(classNames.dropdown.show);
    },
  };

  /* Select ==========================================================================================================
    * You can manage the select options */
  $.Zext.select = {
    init() {
      if (jQuery().select2) { // Check if select2 library installed on page
        $('select').select2();
      }
    },
  };

  /* Dataset List ====================================================================================================
    * You can manage the dataset list options */
  $.Zext.datasetList = {
    init() {
      const $this = this;
      $this.initModal();

      // Create select option list
      // eslint-disable-next-line array-callback-return
      $(selectors.datasetList.featuredList).find('li a').map(() => {
        const $option = $('<option>', { text: $(this).text() });
        $option.attr('value', $(this).data('key'));
        $(selectors.datasetList.featuredSelect).append($option);
      });

      // Change selected value on select list when item click
      $(selectors.datasetList.featuredList).find('li a').on('click', (e) => {
        e.preventDefault();
        $(selectors.datasetList.featuredList).find('li').removeClass('active');
        $(this).parent().addClass('active');

        const key = $(this).data('key');
        $(selectors.datasetList.featuredSelect).val(key);
        $(selectors.datasetList.featuredSelect).select2().trigger('change');
      });

      // Select change event
      $(selectors.datasetList.featuredSelect).on('select2:select', (e) => {
        $(selectors.datasetList.featuredList).find('li').removeClass('active');
        $(selectors.datasetList.featuredList).find(`li a[data-key="${e.params.data.id}"]`).parent().addClass('active');
      });
    },
    initModal() {
      const $this = this;
      const $body = $('body');

      // Open detail modal
      $(selectors.datasetList.buttonFeaturedItemDetail).on('click', $this.openModal);

      // Close detail modal
      $(selectors.datasetList.btnModalClose).on('click', $this.closeModal);

      // Close modal if click handle on outside
      $(selectors.datasetList.modal).on('click', (e) => {
        if ($(e.target).parents('.modal-dialog').length === 0) $this.closeModal();
      });

      // Close modal if ESC press
      $(document).bind('keyup', (e) => {
        if ($body.hasClass(classNames.modal.open) && (e.key === 'Escape' || e.keyCode === 27)) $this.closeModal();
      });

      // Content custom scrollbar
      if (jQuery().mCustomScrollbar) {
        $(selectors.datasetList.modalBody).mCustomScrollbar();
      }
    },
    openModal() {
      const $body = $('body');
      const scrollWidth = `${$.Zext.common.getBrowserScrollWidth()}px`;

      $body.addClass(classNames.modal.open);
      $body.css('padding-right', scrollWidth);
      $(selectors.datasetList.modal).css('padding-right', scrollWidth);
      $('header').css('padding-right', scrollWidth);
    },
    closeModal() {
      const $body = $('body');

      $body.removeClass(classNames.modal.open);
      $body.css('padding-right', '0');
      $(selectors.datasetList.modal).css('padding-right', '0');
      $('header').css('padding-right', '0');
    },
  };

  /* Testimonials ====================================================================================================
    *  You can manage the testimonials options */
  $.Zext.testimonials = {
    init() {
      $(selectors.testimonials.slider).slick({
        dots: true,
        prevArrow: carousel.prevArrow,
        nextArrow: carousel.nextArrow,
      });
    },
  };

  /* Data Market =====================================================================================================
    *  You can manage the data market options */
  $.Zext.dataMarket = {
    init() {
      const $this = this;

      $this.organizeSlider();
      $(window).resize(() => {
        $this.organizeSlider();
      });
    },
    organizeSlider() {
      const width = $(window).width();
      const $slider = $(selectors.dataMarket.slider);

      // Init slider if window width <= values.dataMarketSliderStartWith
      if (width <= values.dataMarketSliderStartWith) {
        if (!$slider.hasClass(classNames.slider.slickCarousel)) {
          $slider.slick({
            slidesToShow: 1,
            dots: true,
            prevArrow: carousel.prevArrow,
            nextArrow: carousel.nextArrow,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                },
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  centerPadding: '15px',
                },
              },
            ],
          });
        }
      } else if ($slider.hasClass(classNames.slider.slickCarousel)) {
        $slider.slick('unslick');
      }
    },
  };

  /* Intro With Half Image ===========================================================================================
    *  You can manage the intro with half image options */
  $.Zext.introWithHalfImage = {
    init() {
      $(selectors.introWithHalfImage.btnReadMore).on('click', () => {
        const hasReadMore = $(selectors.introWithHalfImage.main).hasClass(classNames.introWithHalfImage.readMore);
        $(selectors.introWithHalfImage.main).toggleClass(classNames.introWithHalfImage.readMore);

        if (!hasReadMore) {
          $(selectors.introWithHalfImage.main).find('.summary').addClass('hide');
        } else {
          $(selectors.introWithHalfImage.main).find('.summary').removeClass('hide');
        }
      });
    },
  };

  /* Service Steps ===================================================================================================
    *  You can manage the service steps options */
  $.Zext.serviceSteps = {
    init() {
      const $slider = $(selectors.serviceSteps.slider);

      // Organize arrows positions
      $slider.on('init', (e, slick) => {
        let $centerEl = $(e.target).find('.slick-list [data-slick-index="0"]');
        let centerElLeft = $centerEl.offset().left;
        let centerElWidth = $centerEl.innerWidth();
        let arrowWidth = $(slick.$prevArrow).innerWidth();

        $(e.target).find('.slick-list [data-slick-index="-1"]').addClass('visible-hidden');

        $(e.target).find('.slick-list [data-slick-index="-1"]').addClass('visible-hidden');

        $(slick.$prevArrow).css('left', `${centerElLeft - arrowWidth / 2}px`);
        $(slick.$nextArrow).css('left', `${(centerElWidth + centerElLeft) - arrowWidth / 2}px`);

        $(window).resize(() => {
          // Change arrows positions
          setTimeout(() => {
            $centerEl = $(e.target).find('.slick-list [data-slick-index="0"]');
            centerElLeft = $centerEl.offset().left;
            centerElWidth = $centerEl.innerWidth();
            arrowWidth = $(slick.$prevArrow).innerWidth();

            $(slick.$prevArrow).css('left', `${centerElLeft - arrowWidth / 2}px`);
            $(slick.$nextArrow).css('left', `${(centerElWidth + centerElLeft) - arrowWidth / 2}px`);
          }, 250);
        });
      });

      $slider.on('beforeChange', (e, slick, currentSlide, nextSlide) => {
        // If user click prev arrow on first time, can display last slider item
        if (nextSlide !== 1) $(e.target).find('.slick-list .slick-slide').removeClass('visible-hidden');
      });

      $slider.slick({
        slidesToShow: 1,
        dots: true,
        centerMode: true,
        respondTo: 'slider',
        variableWidth: true,
        prevArrow: carousel.prevArrow,
        nextArrow: carousel.nextArrow,
        responsive: [{
          breakpoint: 992,
          settings: {
            centerMode: false,
            variableWidth: false,
          },
        }],
      });
    },
  };

  /* Pricing Wizard ==================================================================================================
    *  You can manage the pricing wizard options */
  $.Zext.pricingWizard = {
    init() {
      const $slider = $(selectors.pricingWizard.slider);
      const $btnNext = $(selectors.pricingWizard.btnNext);

      // First step set as completed
      $(`${selectors.pricingWizard.processColumn}:eq(0)`).addClass('completed');

      $slider.on('beforeChange', (e, slick, currentSlide, nextSlide) => {
        // Next button hide on slide last item
        if (nextSlide === $(selectors.pricingWizard.slider).find('.slider-item').length - 1) {
          $(e.target).find('.slick-arrow.next').addClass('hide');
        } else {
          $(e.target).find('.slick-arrow.next').removeClass('hide');
        }

        // Prev button hide on slide first item
        if (nextSlide === 0) {
          $(e.target).find('.slick-arrow.prev').addClass('hide');
        } else {
          $(e.target).find('.slick-arrow.prev').removeClass('hide');
        }

        // Pricing wizard navigation change scroll position when slider position changed
        $(selectors.pricingWizard.navigationHorizontal).animate({ scrollLeft: nextSlide * values.pricingWizardNavigationMinWidth }, 250);
      });

      $slider.on('afterChange', (e, slick, currentSlide) => {
        $(selectors.pricingWizard.processColumn).removeClass('completed');
        for (let i = 0; i < (currentSlide + 1); i += 1) {
          $(`${selectors.pricingWizard.processColumn}:eq(${i})`).addClass('completed');
        }
      });

      $slider.slick({
        slidesToShow: 1,
        dots: false,
        infinite: false,
        prevArrow: carousel.prevArrow,
        nextArrow: carousel.nextArrow,
        responsive: [{
          breakpoint: 992,
          settings: {
            dots: true,
          },
        }],
      });

      // Prev button hide on load event
      $slider.find('.slick-arrow.prev').addClass('hide');

      // Next button on handle click
      $btnNext.on('click', () => {
        $slider.slick('slickNext');
      });

      // $(selectors.pricingWizard.processColumn).on('click', function () {
      //     $slider.slick('slickGoTo', $(this).index());
      // });
    },
  };

  /* Hero - Video ====================================================================================================
    *  You can manage the hero video options */
  $.Zext.heroVideo = {
    init() {
      $(selectors.hero.video.btnPlay).on('click', () => {
        $(selectors.hero.video.main).addClass('play');

        if ($(selectors.hero.video.source).attr('src') === undefined) {
          const src = $(selectors.hero.video.source).data('src');
          $(selectors.hero.video.source).attr('src', src);
          $(selectors.hero.video.video)[0].load();
          $(selectors.hero.video.video)[0].play();
        } else {
          $(selectors.hero.video.video)[0].load();
          $(selectors.hero.video.video)[0].play();
        }
      });

      $(selectors.hero.video.closeBtn).on('click', () => {
        $(selectors.hero.video.main).removeClass('play');
        $(selectors.hero.video.video)[0].pause();
      });
    },
  };

  /* Page Load - Function ============================================================================================
    *  You can manage the function when page loaded */
  $(() => {
    $.Zext.common.init();
    $.Zext.header.init();
    $.Zext.dropdown.init();
    $.Zext.select.init();
    $.Zext.testimonials.init();
    $.Zext.serviceSteps.init();
    $.Zext.dataMarket.init();
    $.Zext.heroVideo.init();
    $.Zext.datasetList.init();
    $.Zext.introWithHalfImage.init();
    $.Zext.pricingWizard.init();
    $.Zext.preloader.init();
  });
});

export default siteJs;
