<template>
  <div>
    <section v-if="Object.keys(contentDetail).length > 0" class="process-automation">
      <div class="container">
        <div class="card">
          <div class="header">
            <div class="title m-0">{{ contentDetail.title.rendered }}</div>
          </div>
          <div v-if="typeof contentDetail.acf.short_description !== 'undefined'" class="body">
            <div class="left">
              <div class="img-container">
                <img :src="contentDetail.jetpack_featured_media_url" :alt="contentDetail.title.rendered"/>
              </div>
            </div>
            <div class="right">
              <div class="text">{{ stripHtml(contentDetail.acf.short_description) }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="Object.keys(contentDetail).length > 0" class="content mt-4">
      <div class="container">
        <div class="card">
          <div v-html="contentDetail.content.rendered"></div>
        </div>
      </div>
    </section>

    <MakeSchedule v-if="loading === false"></MakeSchedule>
  </div>
</template>

<script>
import MakeSchedule from '@/components/MakeSchedule.vue';
import siteJs from '@/assets/js/site';
import { mapState } from 'vuex';

export default {
  name: 'ContentDetail',
  components: { MakeSchedule },
  computed: {
    ...mapState({
      contentDetail: (state) => state.contentDetail,
    }),
  },
  data: () => ({
    loading: true,
  }),
  beforeCreate() {
    $('body').addClass('page-solution-detail');
  },
  created() {
    this.$store.dispatch('getContentDetail', this.$route.params.name).then(() => {
      siteJs();
      this.loading = false;
      this.setPageTitle(this.contentDetail.title.rendered);
    }).catch(() => {
      this.$router.push({ path: '/404' });
    });
  },
};
</script>

<style scoped>

</style>
