<template>
  <section class="intro-with-half-image" :class="{ 'with-filter-form': filterActive }">
    <div class="container">
      <div :class="`intro-container ${backgroundClass}`">
        <div class="left">
          <div class="top">
            <div class="title">{{ data.acf.title }}</div>
            <div class="summary">{{ data.acf.short_description }}</div>
            <div class="read-more-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <g fill="none" fill-rule="evenodd">
                  <g transform="translate(-219 -632) translate(219 631) rotate(-90 16.5 16.5)">
                    <circle cx="16" cy="16" r="16" fill="#FFF"/>
                    <g stroke="#4A4A4A" stroke-linecap="round" stroke-linejoin="round"
                       stroke-width="1.2">
                      <path d="M11.074 8.39l.008-9.575-.008 9.575z"
                            transform="translate(8.593 13.185) rotate(45 11.078 3.603)"/>
                      <path d="M4.11 8.39l.007-9.575L4.11 8.39z"
                            transform="translate(8.593 13.185) scale(-1 1) rotate(45 0 -6.328)"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="text">
<!--            <div class="subtext">{{ data.acf.sub_text }}</div>-->
            <p style="top: 0px;">{{ data.acf.main_description_left }}</p>
          </div>
        </div>
        <div class="right">
          <div
            class="intro-image-holder"
            :style="`background-image: url('${data.acf.big_image}')`"
          ></div>
          <div class="text">
            {{ data.acf.main_description_right }}
          </div>
        </div>
      </div>

      <div class="intro-container-for-mobile bg-col-lavender">
        <div class="intro-image-holder"
             :style="`background-image: url('${data.acf.big_image}')`"></div>
        <div class="content">
          <div class="top">
            <div class="title">{{ data.acf.title }}</div>
            <div class="summary">{{ data.acf.short_description }}</div>
            <div class="read-more-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <g fill="none" fill-rule="evenodd">
                  <g transform="translate(-219 -632) translate(219 631) rotate(-90 16.5 16.5)">
                    <circle cx="16" cy="16" r="16" fill="#FFF"></circle>
                    <g stroke="#4A4A4A" stroke-linecap="round" stroke-linejoin="round"
                       stroke-width="1.2">
                      <path d="M11.074 8.39l.008-9.575-.008 9.575z"
                            transform="translate(8.593 13.185) rotate(45 11.078 3.603)"></path>
                      <path d="M4.11 8.39l.007-9.575L4.11 8.39z"
                            transform="translate(8.593 13.185) scale(-1 1) rotate(45 0 -6.328)"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="text">
            <div class="subtext">{{ data.acf.sub_text }}</div>
            <p>{{ data.acf.main_description_right }}</p>
          </div>
        </div>
      </div>
    </div>

    <section v-if="filterActive" class="filter-form">
      <div class="container">
        <form class="form-filters">
          <div class="column">
            <select id="scopes" v-model="selectedFilter.scopes">
              <option value="">By Scopes</option>
              <option v-for="(item, index) in filterList['solutions-by-scopes']" :key="index" :value="item.slug">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column">
            <select id="industries" v-model="selectedFilter.industries">
              <option value="">By Industries</option>
              <option v-for="(item, index) in filterList['solution']" :key="index" :value="item.slug">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column">
            <select id="departments" v-model="selectedFilter.departments">
              <option value="">By Departments</option>
              <option v-for="(item, index) in filterList['solutions-by-departments']" :key="index" :value="item.slug">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column">
            <select id="types" v-model="selectedFilter.types">
              <option value="">By Source Types</option>
              <option v-for="(item, index) in filterList['solutions-by-source-types']" :key="index" :value="item.slug">
                {{ item.name }}
              </option>
            </select>
          </div>
        </form>
      </div>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PageIntro',
  props: ['filterActive', 'backgroundClass', 'data'],
  computed: {
    ...mapState({
      filterList: (state) => state.filterList,
    }),
  },
  mounted() {
    $('select').select2().on('select2:select select2:unselecting', (e) => {
      $(`select:not(#${e.target.id})`).val('').trigger('change');
      this.selectedFilter[e.target.id] = e.target.value;
      this.$emit('filter-changed', e.target.value);
    });
  },
  data() {
    return {
      selectedFilter: {
        scopes: '',
        industries: '',
        departments: '',
        types: '',
      },
    };
  },
};
</script>

<style scoped>

</style>
