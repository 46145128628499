export default {
  namespaced: true,
  state: {
    category: 'company',
    leaderBoard: {},
    companyInfo: {},
  },
  getters: {
    leaderBoard(state) {
      return state.leaderBoard;
    },
    companyInfo(state) {
      return state.companyInfo;
    },
    category(state) {
      return state.category;
    },
  },
  actions: {
    getLeaderBoard({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'leaderboard' }, { root: true })
        .then((res) => {
          if (res.length > 0) {
            commit('SET_LEADERBOARD', res[0]);
          }
        })
        .catch();
    },
    getCompanyInfo({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'company-info' }, { root: true })
        .then((res) => {
          if (res.length > 0) {
            commit('SET_COMPANY_INFO', res[0]);
          }
        })
        .catch();
    },
  },
  mutations: {
    SET_LEADERBOARD: (state, data) => {
      state.leaderBoard = data;
    },
    SET_COMPANY_INFO: (state, data) => {
      state.companyInfo = data;
    },
  },
};
