export default {
  namespaced: true,
  state: {
    category: 'homepage',
    headline: {},
    dataSetList: [],
    blogList: [],
    howToServeData: [],
    testimonials: [],
  },
  getters: {
    blogList(state) {
      return state.blogList;
    },
    howToServeData(state) {
      return state.howToServeData;
    },
    dataSetList(state) {
      return state.dataSetList;
    },
    category(state) {
      return state.category;
    },
    testimonials(state) {
      return state.testimonials;
    },
  },
  actions: {
    getHeadline({ commit, getters }) {
      return this.dispatch('getHomeHeadline', { category: getters.category, tag: 'headline' }, { root: true })
        .then((res) => {
          commit('SET_HOME_HEADLINE', res);
        })
        .catch();
    },
    getBlogList({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'blog' }, { root: true })
        .then((res) => {
          commit('SET_BLOG_LIST', res);
        })
        .catch();
    },
    getDataSetList({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'dataset-list' }, { root: true })
        .then((res) => {
          commit('SET_DATASET_LIST', res);
        })
        .catch();
    },
    getHowToServeData({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'how-to-serve' }, { root: true })
        .then((res) => {
          commit('SET_SERVE_DATA', res);
        })
        .catch();
    },
    getTestimonials({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'testimonial' }, { root: true })
        .then((res) => {
          commit('SET_TESTIMONIALS', res);
        })
        .catch();
    },
  },
  mutations: {
    SET_HOME_HEADLINE: (state, data) => {
      const [post] = data;
      state.headline = post;
    },
    SET_BLOG_LIST: (state, data) => {
      state.blogList = data;
    },
    SET_DATASET_LIST: (state, data) => {
      state.dataSetList = data;
    },
    SET_SERVE_DATA: (state, data) => {
      state.howToServeData = data;
    },
    SET_TESTIMONIALS: (state, data) => {
      state.testimonials = data;
    },
  },
};
