<template>
  <section class="request-a-demo">
    <div class="container">
      <div class="card">
        <div class="left">
          <div class="title">Service Connectivity for Modern Architectures</div>
          <div class="text">
            Build apps faster with a full stack platform that goes from API management to Service Mesh, across
            all your clouds. Kubernetes native.
          </div>
        </div>
        <div class="right">
          <div class="top">
            <form class="form-request-demo">
              <div class="form-group">
                <input type="email" placeholder="Work e-mail address" required/>
                <button class="btn btn-danger" type="submit">
                  <span>Get Started</span>
                </button>
              </div>
            </form>
          </div>
          <div class="bottom">
            <div class="link-text">
              Contact Technical Sales?
              <a href="#">Request a Demo</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RequestDemo',
};
</script>

<style scoped>

</style>
