<template>
  <div>
    <PageIntro v-if="Object.keys(leaderBoard).length > 0"
               @filter-changed="onFilterChanged"
               :filterActive="true"
               :backgroundClass="'bg-col-lavender'"
               :data="leaderBoard"></PageIntro>

    <section class="industry-and-brands">
      <div class="container">
        <div v-if="Object.keys(promotionText).length > 0" class="header">
          <div class="img-container">
            <img :src="promotionText.jetpack_featured_media_url" alt=""/>
          </div>
          <div class="content-container">
            <div class="title">{{ promotionText.title.rendered }}</div>
            <div class="text">{{ stripHtml(promotionText.content.rendered) }}</div>
          </div>
        </div>

        <div v-if="solutionList.length > 0" class="brands-container" style="margin-bottom: 20px">
          <div v-for="(item, index) in solutionList" :key="index" class="card">
            <div class="top">
              <div class="img-container">
                <img
                  :src="item.jetpack_featured_media_url"
                  :alt="item.title.rendered"
                />
              </div>
              <div class="content-container">
                <div class="title" style="font-size: 22px; font-weight: 600; margin-bottom: 10px;">{{ item.title.rendered }}</div>
                <div class="text">{{ substringWithChar(item.content.rendered, '.', 180) }}</div>
              </div>
            </div>
            <div class="bottom">
              <div class="button-container">
                <a :href="`/content/${item.slug}`" class="btn btn-transparent">
                  <span>Read</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <g transform="translate(-839 -1448) translate(130 1249) translate(709 199)">
                            <rect width="32" height="32" fill="#000" rx="4.8"/>
                            <g stroke="#FFF" stroke-linecap="round" stroke-linejoin="round"
                               stroke-width="1.2">
                              <g>
                                <path d="M11.074 8.39l.008-9.575-.008 9.575z"
                                      transform="rotate(-90 18.37 5.333) translate(.296 .148) rotate(45 11.078 3.603)"/>
                                <path d="M4.11 8.39l.007-9.575L4.11 8.39z"
                                      transform="rotate(-90 18.37 5.333) translate(.296 .148) scale(-1 1) rotate(45 0 -6.328)"/>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
<!--    <RequestDemo></RequestDemo>-->
    <MakeSchedule></MakeSchedule>
  </div>
</template>

<script>
import siteJs from '@/assets/js/site';
import MakeSchedule from '@/components/MakeSchedule.vue';
import PageIntro from '@/components/PageIntro.vue';
import { mapState } from 'vuex';

export default {
  name: 'Solutions',
  components: { PageIntro, MakeSchedule },
  computed: {
    ...mapState({
      leaderBoard: (state) => state.solution.leaderBoard,
      promotionText: (state) => state.solution.promotionText,
      solutionList: (state) => state.solution.solutionList.slice(0, 6),
    }),
  },
  beforeCreate() {
    $('body').addClass('page-solutions');
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('solution/getLeaderBoard'),
      this.$store.dispatch('solution/getPromotionText'),
      this.$store.dispatch('solution/getSolutionList'),
    ]);
    this.setPageTitle(this.leaderBoard.title.rendered);
    siteJs();
  },
  methods: {
    async onFilterChanged(data) {
      if (data.length > 0) {
        await Promise.all([
          this.$store.dispatch('solution/getPromotionText', data),
          this.$store.dispatch('solution/getSolutionList', data),
        ]);
      } else {
        await Promise.all([
          this.$store.dispatch('solution/getPromotionText'),
          this.$store.dispatch('solution/getSolutionList'),
        ]);
      }
    },
  },
};
</script>
