<template>
  <div>
    <PageIntro v-if="Object.keys(leaderBoard).length > 0" :filterActive="false" :backgroundClass="'bg-col-pale-sky-blue'" :data="leaderBoard"></PageIntro>

    <section v-if="promotionText.length > 0" class="pricing-content">
      <div class="container">
        <div class="title">{{ promotionText.title.rendered }}</div>
        <div class="sub-title">{{ promotionText.acf.sub_title }}</div>
        <div class="text">{{ stripHtml(promotionText.content.rendered) }}</div>
      </div>
    </section>

    <section v-if="pricingList.length > 0" class="pricing-wizard">
      <div class="container">
        <div class="navigation">
          <div class="card">
            <div v-for="(item, index) in pricingList" :key="index" class="process-column">
              <div class="checkmark">
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20">
                  <g fill="none" fill-rule="evenodd">
                    <g stroke="#6BD958" stroke-width="3">
                      <path d="M309 1296.512L316.528 1302 329 1286" transform="translate(-308 -1285)"/>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="label">{{ item.title.rendered }}</div>
              <div class="circle">
                <div class="selection">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>

        <div class="slider-container">
          <div v-for="(item, index) in pricingList" :key="index" class="slider-item">
            <div class="main-title">{{ item.title.rendered }}</div>
            <div class="card">
              <div class="header">
                <div class="left">
                  <img :src="item.jetpack_featured_media_url" alt="Zext"/>
                </div>
                <div class="right">
                  <div class="title">{{ item.acf.sub_title }}</div>
                  <div class="text">{{ stripHtml(item.content.rendered) }}</div>
                </div>
              </div>
              <div class="footer">
                <div class="left">
                  <div class="info">Would you like to have a plan in this area?</div>
                  <button class="btn btn-danger">
                    <span>Pick a Plan</span>
                  </button>
                </div>
                <div class="right">
                  <button class="btn btn-primary btn-next">
                    <span>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <RequestDemo></RequestDemo>
    <MakeSchedule></MakeSchedule>
  </div>
</template>

<script>
import RequestDemo from '@/components/RequestDemo.vue';
import MakeSchedule from '@/components/MakeSchedule.vue';
import siteJs from '@/assets/js/site';
import PageIntro from '@/components/PageIntro.vue';
import { mapState } from 'vuex';

export default {
  name: 'Pricing',
  components: { PageIntro, MakeSchedule, RequestDemo },
  computed: {
    ...mapState({
      leaderBoard: (state) => state.pricing.leaderBoard,
      promotionText: (state) => state.pricing.promotionText,
      pricingList: (state) => state.pricing.pricingList,
    }),
  },
  beforeCreate() {
    $('body').addClass('page-pricing');
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('pricing/getLeaderBoard'),
      this.$store.dispatch('pricing/getPromotionText'),
      this.$store.dispatch('pricing/getPricingList'),
    ]);
    siteJs();
  },
};
</script>

<style scoped>

</style>
