import axios from 'axios';

const services = {
  async post(url, payload) {
    const options = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post(url, payload, options)
      .then((res) => res)
      .catch((err) => err);

    return data;
  },
  async delete(url, params) {
    const options = {
      headers: {
        'Content-type': 'application/json',
      },
      params,
    };

    const { data } = await axios.delete(url, options)
      .then((res) => res)
      .catch((err) => err);

    return data;
  },
  async get(url, params) {
    const options = {
      headers: {
        'Content-type': 'application/json',
      },
      params,
    };

    const { data } = await axios.get(url, options)
      .then((res) => res)
      .catch((err) => err);

    return data;
  },
  async put(url, payload) {
    const options = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.put(url, payload, options)
      .then((res) => res)
      .catch((err) => err);

    return data;
  },
};

export default services;
