<template>
  <header>
    <div class="container">
      <!-- Logo & Mobile Toggle -->
      <div class="logo-toggle-container">
        <!-- Logo -->
        <a href="/">
          <img src="@/assets/images/logo.svg" width="166" height="47" alt="Zext"/>
        </a>
        <!-- #END# Logo -->

        <!-- Mobile Navbar Toggle Items -->
        <div class="navbar-toggle">
          <ul>
            <li class="navbar-icon-search">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                  <g stroke="#000" stroke-width="1.6">
                    <g>
                      <g transform="translate(-222 -33) translate(-.004) translate(223 34)">
                        <circle cx="11.722" cy="11.722" r="7.722"
                                transform="rotate(-45 11.722 11.722)"/>
                        <path d="M19.002 21.576L19.002 16.428"
                              transform="rotate(-45 19.002 19.002)"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
            <li class="navbar-icon-toggle">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="9" viewBox="0 0 24 9">
                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                  <g stroke="#000" stroke-width="1.6">
                    <g>
                      <g>
                        <path d="M22 .5L0 .5M11.5 6.5L0 6.5"
                              transform="translate(-269 -41) translate(-.004) translate(270 42)"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
            <li class="navbar-icon-close">
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                  <g stroke="#000" stroke-width="1.6">
                    <g>
                      <path d="M9.5 -2L9.5 20"
                            transform="translate(-272 -37) translate(272 37) rotate(45 9.5 9)"/>
                      <path d="M20 9.5L-2 9.5"
                            transform="translate(-272 -37) translate(272 37) rotate(45 9 9.5)"/>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
          </ul>
        </div>
        <!-- #END# Mobile Navbar Toggle Items -->
      </div>
      <!-- #END# Logo & Mobile Toggle -->

      <!-- Menu Items -->
      <div class="other-container">
        <!-- Links -->
        <div class="header-links-container">
          <div class="sub-container">
            <ul class="link-list">
              <li v-for="(item, index) in headerMenu" :key="index"
                  class="main-list-item"
                  :class="{ 'dropdown': item.sub.length > 0, 'active': currentPath.includes(item.path) }"
              >
                <a class="dropdown-toggle main-link" :href="item.path">
                  <span>
                    {{ item.name }}
                    <svg v-if="item.sub.length > 0"
                         xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g transform="translate(-691 -74) translate(691 74)">
                          <circle cx="6" cy="6" r="6" fill="#9595FB"/>
                          <g stroke="#FFF" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="1.2">
                            <path d="M4.375 3.59L4.378 0l-.003 3.59z"
                                  transform="translate(3 4.5) rotate(45 4.376 1.795)"/>
                            <path d="M1.763 3.59L1.766 0l-.003 3.59z"
                                  transform="translate(3 4.5) scale(-1 1) rotate(45 0 -2.465)"/>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  </span>
                </a>
                <div v-if="item.sub.length > 0" class="dropdown-menu">
                  <div class="left">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         width="60" height="60" viewBox="0 0 60 60">
                      <defs>
                        <path id="o3hb3v9qba"
                              d="M45.205 45.233L59.354 45.233 59.354 59.382 45.205 59.382z"/>
                        <path id="ljr4dgrv9c" d="M0 45.233L13.901 45.233 13.901 59.382 0 59.382z"/>
                      </defs>
                      <g fill="none" fill-rule="evenodd" opacity=".2">
                        <g>
                          <g>
                            <g transform="translate(-334 -235) translate(294 160) translate(40 75)">
                              <path fill="#CBCBCB"
                                    d="M37 29c0 3.866-3.134 7-7 7s-7-3.134-7-7 3.134-7 7-7 7 3.134 7
                                    7M59 7c0 3.866-3.134 7-7 7s-7-3.134-7-7 3.134-7 7-7 7 3.134 7
                                    7M14 7c0 3.866-3.134 7-7 7s-7-3.134-7-7 3.134-7 7-7 7 3.134 7 7"/>
                              <mask id="3hd5k1gusb" fill="#fff">
                                <use xlink:href="#o3hb3v9qba"/>
                              </mask>
                              <path fill="#CBCBCB"
                                    d="M59.354 52.307c0 3.908-3.167 7.075-7.075 7.075-3.907 0-7.074-3.167-7.074-7.075
                                    0-3.907 3.167-7.074 7.074-7.074 3.908 0 7.075 3.167 7.075 7.074"
                                    mask="url(#3hd5k1gusb)"/>
                              <mask id="n4m0a43nld" fill="#fff">
                                <use xlink:href="#ljr4dgrv9c"/>
                              </mask>
                              <path fill="#CBCBCB"
                                    d="M13.901 52.307c0 3.908-3.112 7.075-6.95 7.075-3.84 0-6.95-3.167-6.95-7.075
                                    0-3.907 3.11-7.074 6.95-7.074 3.838 0 6.95 3.167 6.95 7.074"
                                    mask="url(#n4m0a43nld)"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="right" :class="{ 'two-col': item.sub.length > 3 }">
                    <ul class="dropdown-menu-list">
                      <li v-for="(subItem, subIndex) in item.sub" :key="subIndex"
                          class="list-item"
                          :class="{ 'active': `/${subItem.path}` === currentPath }"
                      >
                        <a class="link" :href="`/${subItem.path}`">{{ subItem.name }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
<!--            <div class="search-container">-->
<!--              <form class="form-header-search" method="get">-->
<!--                <div class="form-group">-->
<!--                  <input autocomplete="off" class="search-input" name="term" type="text"-->
<!--                         placeholder="Type the word you are looking for" required/>-->
<!--                  <div class="close-search">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">-->
<!--                      <g fill="none" fill-rule="evenodd">-->
<!--                        <g stroke="#4A4A4A" stroke-width=".5">-->
<!--                          <g>-->
<!--                            <path d="M7 0L7 14M14 7L0 7"-->
<!--                                  transform="translate(-1209 -71) rotate(45 523.674 1507.013)"/>-->
<!--                          </g>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                    </svg>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </form>-->
<!--            </div>-->
          </div>

          <div class="term-suggestion-container">
            <div class="container">
              <div class="content-container">
                <div class="title">Try Searching For</div>

                <ul class="quick-term-list">
                  <li class="list-item">Automation types</li>
                  <li class="list-item">Pricing plans</li>
                  <li class="list-item">Facebook</li>
                  <li class="list-item">Data types</li>
                  <li class="list-item">Energy charts</li>
                  <li class="list-item">Email templates</li>
                  <li class="list-item">Build an audience</li>
                  <li class="list-item">Instagram</li>
                </ul>

                <div class="info">
                  <p>If you want to make a quick call.</p>
                  <p>Please select the <strong>tags</strong> that suit you.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- #END# Links -->

        <!-- Lang Choose -->
<!--        <div class="lang-others-container">-->
<!--          <div class="dropdown lang-container">-->
<!--            <a class="dropdown-toggle" href="#">-->
<!--              <span>{{ languageList[selectedLanguage].name }}</span>-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5">-->
<!--                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">-->
<!--                  <g stroke="#B19B76">-->
<!--                    <g>-->
<!--                      <g>-->
<!--                        <g>-->
<!--                          <path d="M4.375 3.59L4.378 0l-.003 3.59z"-->
<!--                                transform="translate(-1508 -34) translate(1461 29)-->
<!--                                translate(48 5) translate(0 .5) rotate(45 4.376 1.795)"/>-->
<!--                          <path d="M1.763 3.59L1.766 0l-.003 3.59z"-->
<!--                                transform="translate(-1508 -34) translate(1461 29)-->
<!--                                translate(48 5) translate(0 .5) scale(-1 1) rotate(45 0 -2.465)"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                    </g>-->
<!--                  </g>-->
<!--                </g>-->
<!--              </svg>-->
<!--            </a>-->
<!--            <div class="dropdown-menu">-->
<!--              <ul class="lang-list">-->
<!--                <li v-for="(item, index) in languageList" :key="index" class="list-item">-->
<!--                  <a @click="changeLanguage(index)" class="link" href="#">{{ item.name }}</a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--          <a href="#" class="btn btn-danger">-->
<!--            <span>Get Started</span>-->
<!--          </a>-->
<!--        </div>-->
        <!-- #END# Lang Choose -->
      </div>
      <!-- #END# Menu Items -->
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Header',
  computed: {
    ...mapState({
      headerMenu: (state) => state.headerMenu,
      languageList: (state) => state.languageList,
      selectedLanguage: (state) => state.selectedLanguage,
    }),
  },
  data() {
    return {
      currentPath: this.$route.path,
    };
  },
  methods: {
    changeLanguage(index) {
      this.$session.set('lang', index);
      this.$store.commit('SET_SELECTED_LANGUAGE', index);
      this.$router.go(0);
    },
  },
};
</script>

<style scoped>

</style>
