export default {
  namespaced: true,
  state: {
    category: 'resources',
    leaderBoard: {},
    resourcesList: [],
    promotionText: {},
  },
  getters: {
    leaderBoard(state) {
      return state.leaderBoard;
    },
    promotionText(state) {
      return state.promotionText;
    },
    category(state) {
      return state.category;
    },
    resourcesList(state) {
      return state.resourcesList;
    },
  },
  actions: {
    getLeaderBoard({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'leaderboard' }, { root: true })
        .then((res) => {
          if (res.length > 0) {
            commit('SET_LEADERBOARD', res[0]);
          }
        })
        .catch();
    },
    getPromotionText({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'promotion-text' }, { root: true })
        .then((res) => {
          if (res.length > 0) {
            commit('SET_PROMOTION_TEXT', res[0]);
          }
        })
        .catch();
    },
    getResourceList({ commit, getters }) {
      return this.dispatch('getDataByPageAndTag', { category: getters.category, tag: 'resources-list' }, { root: true })
        .then((res) => {
          commit('SET_RESOURCES_LIST', res);
        })
        .catch();
    },
  },
  mutations: {
    SET_LEADERBOARD: (state, data) => {
      state.leaderBoard = data;
    },
    SET_PROMOTION_TEXT: (state, data) => {
      state.promotionText = data;
    },
    SET_RESOURCES_LIST: (state, data) => {
      state.resourcesList = data;
    },
  },
};
